
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.check-balance-link {
  width: auto;
  color: color.$primary-color;
  padding: 0;
  font-weight: 100;
  border: none;
  margin-left: 5px;

  &:hover {
    transform: none;
    text-decoration: underline;
  }
}
