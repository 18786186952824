
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.pre-sell-item-wrap {
  margin-bottom: 15px;
}
.pre-sell-date {
  display: block;
  color: color.$light-green;
  margin-bottom: 1px;
  font-weight: typography.$bold;
}
