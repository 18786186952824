
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.pdpbreadcrumb {
  display: flex;
  padding: 10px 0;
  background: 0 0;
  font-size: 11px;
  border-radius: 0;
  font-weight: typography.$light;

  .pdpbreadcrumb-list {
    padding-top: 12px;
    li {
      display: inline-block;
      &:last-child {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 40%;
        text-wrap: nowrap;
        white-space: nowrap;
        overflow: hidden;
        vertical-align: middle;
        @include breakpoint('xlarge') {
          width: 32%;
        }
      }
    }
  }

  .separator {
    &:before {
      width: 0;
      content: '';
      height: 0;
      margin-left: 8px;
      margin-right: 14px;
      overflow: hidden;
      display: inline-block;
      border-left: 4px solid color.$red-crimson;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
    }
  }

  a {
    color: color.$black-color;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .breadcrumb {
    @media (min-width: 767px) and (max-width: 991px) {
      width: 50%;
    }
  }
}

.breadcrumb {
  .reviewText {
    padding: 12px 0 0px 0;
    font-size: 14px;
    white-space: nowrap;
    text-align: left;
    display: flex;
    @include breakpoint('medium') {
      font-size: 13px;
      padding: 12px 0 12px 0;
    }
    a {
      color: color.$primary-color;
      cursor: pointer;
      text-decoration: none;
      margin-left: 5px;

      &:hover {
        text-decoration: underline;
      }
    }

    .pdpreviewstars {
      display: flex;
      gap: 10px;
      position: relative;

      .item_rating {
        .stars {
          width: 100px;
          height: 18px;
          background: variables.$background-lakeshore-sprites -400px -442px/1000px
            1000px;
          position: relative;
          float: left;
        }

        .stars span {
          height: 18px;
          background: variables.$background-lakeshore-sprites -300px -442px/1000px
            1000px;
          position: absolute;
        }

        .gold {
          color: color.$yellow2;
        }
      }
    }

    .stars {
      width: 100px;
      height: 18px;
      background: variables.$background-lakeshore-sprites -400px -442px/1000px
        1000px;
    }

    @media (max-width: 768px) {
      .pdpreviewstars {
        left: 0%;
        justify-content: flex-start;
      }
    }

    .item_ratings {
      display: flex;
      gap: 10px;
    }
    .numric-rating {
      margin-top: 2px;
    }
  }
}

.pdpbreadcrumb_div {
  margin-right: auto;
}

.reviewLink {
  color: color.$primary-color;
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  @media screen and (min-width: 768px) {
    font-size: 12px;
    margin-left: 0;
    margin-top: -1px;
  }
}
