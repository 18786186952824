
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.product-title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.product-title {
  font-size: 28px;
  font-weight: typography.$bold;
  color: color.$black-color;

  @media screen and (max-width: 767px) {
    font-size: 25px;
    width: 100%;
  }
}

.new-product {
  background-color: color.$tangerine2;
  padding: 3px 10px;
  display: inline-block;
  margin: 0 10px;
  font-size: 12.6px;
  font-weight: typography.$bold;
  color: color.$white-color;
  vertical-align: middle;
  border-radius: 5px;
}

.exclusive {
  color: color.$light-green;
  font-size: 12.6px;
  font-weight: typography.$bold;
  line-height: 1;
}
