
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.mainModalClass {
  padding: 0;
  @include breakpoint('large') {
    padding: 10px;
  }

  button[class*='btn-close'] {
    min-width: auto;
    background-color: transparent;
    background: none;
    color: color.$black-color;
    font-size: 30px;
    font-weight: 200;
    opacity: 0.2;
    &::after {
      content: '×';
      @media screen and (min-width: 992px) {
        display: none;
      }
    }
    &:hover {
      opacity: 0.5;
      @media screen and (min-width: 992px) {
        opacity: 1;
      }
    }
    @media screen and (min-width: 992px) {
      border: none;
      padding: 0;
      position: absolute;
      opacity: 1;
      right: -5px;
      top: -5px;
      width: 35px;
      height: 35px;
      background: variables.$background-lakeshore-sprites 0 -105px / 350px 350px;
    }
  }
  .change-store {
    background-color: rgb(255, 254, 234);
    padding: 20px;
    border-top: 1px solid rgb(204, 204, 204);
    border-bottom: 1px solid rgb(204, 204, 204);
    @include breakpoint('medium') {
      margin-left: -25px;
    }
    .change-store-wrapper {
      justify-content: right;
      margin-top: 10px;
      @include breakpoint('medium') {
        display: flex;
      }
    }
    .cancel-change-store {
      margin-right: 20px;
      margin-top: 15px;
      @include breakpoint('medium') {
        margin-top: 0;
      }
    }
    .cancel-change-store,
    .make-your-store-button {
      display: block;
      width: 100%;
      height: 38px;
      @include breakpoint('medium') {
        width: auto;
        height: auto;
      }
    }
  }

  .modalContainer {
    color: color.$black-color;
    margin-top: 125px;
    margin-left: -8px;
    margin-right: -8px;
    border-radius: 0;
    width: calc(100% + 17px) !important;
    position: relative;
    &::after {
      content: '';
      width: 20px;
      height: 20px;
      display: block;
      border: 11px solid transparent;
      border-bottom-color: #fff;
      position: absolute;
      top: -22px;
      left: 64px;
    }
    &.plpModalContainer {
      margin-top: 0;
      margin-left: 16px;
      margin-right: 16px;
      @include breakpoint('medium') {
        margin-left: 0;
        margin-right: 0;
      }
    }
    @include breakpoint('medium') {
      border-radius: 0.5rem;
      margin-right: 0;
      margin-top: 108px;
      &::after {
        content: none;
      }
    }
    div[class*='modal-header'] {
      border-bottom: none;
      padding: 20px 25px 0;
      background: color.$white-color;
      border-radius: 0px;
      button {
        top: 16px;
        right: 25px;
        width: 18px;
        background: url(../../../../../public/assets/images/cross-icon.webp)
          no-repeat;
        @include breakpoint('medium') {
          top: -10px;
          right: -10px;
          width: 35px;
          background: variables.$background-lakeshore-sprites 0 -105px/350px
            350px;
        }
      }
      &.blue-header {
        padding: 20px 25px 20px;
        background: color.$primary-color;
        h3 {
          color: color.$white-color;
        }
      }
      @media screen and (min-width: 768px) {
        background: color.$primary-color;
        border-radius: 4px 4px 0 0;
        padding: 20px;
      }
    }

    div[class*='modal-body'] {
      padding: 0;
      @media screen and (min-width: 768px) {
        padding-right: 25px;
        padding-left: 25px;
        padding-top: 20px;
      }
    }
    h3 {
      font-size: 22px;
      color: color.$black-color;
      font-weight: typography.$bold;
      line-height: 1.23;
      @media screen and (min-width: 768px) {
        color: color.$white-color;
        margin: 0;
      }
    }

    h4 {
      margin-bottom: 5px;
      font-size: 20px;
      line-height: 29px;
      font-weight: typography.$bold;
    }

    .instoreIcon {
      width: 20px;
      height: 20px;
      background: variables.$background-lakeshore-sprites -140px 0 / 200px 200px;
      text-align: center;
    }
    .plusIcon {
      width: 8px;
      height: 8px;
      background: variables.$background-lakeshore-sprites -64px 0 / 80px 80px;
      text-align: center;
      margin: 0 5px;
    }
    .curbsideIcon {
      width: 25px;
      height: 20px;
      background: variables.$background-lakeshore-sprites -225px -2px / 250px
        250px;
      text-align: center;
    }
    .msgText {
      margin-left: 4px;
      font-size: 13px;
      line-height: 1.4em;
    }
    .locationIconWrp {
      padding: 25px 25px 0;
      @include breakpoint('medium') {
        padding: 0;
      }
    }

    .locationIcon {
      filter: invert(1);
      display: inline-block;
      width: 20px;
      margin-right: 5px;
      height: 20px;
      margin-top: -3px;
      vertical-align: middle;
      background: variables.$background-lakeshore-sprites -80px 0 / 200px 200px;
    }
    .inputClass {
      input {
        padding-top: 0;
        padding-bottom: 0;
        line-height: 1.2;
        border-radius: 4px;
        border: 1px solid color.$light-gray;
        height: 34px;
        padding: 6px 12px;
        font-size: 16px;
        color: color.$grey10;
        background-color: color.$white-color;

        @include breakpoint('medium') {
          font-size: 14px;
        }
        &::placeholder {
          color: color.$gray-silver;
        }
        &:focus {
          border-color: color.$light-blue;
        }
      }
    }
    .inputContainer {
      padding-top: 25px;
      padding-bottom: 20px;
      padding-left: 25px;
      padding-right: 25px;
      @media screen and (min-width: 768px) {
        padding-top: 10px;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 5px;
      }
      .errorMsg {
        color: color.$red-neon;
        font-weight: typography.$light;
        letter-spacing: -0.015em;
        font-size: 14px;
        margin: 10px 0 0;
        line-height: 22px;
      }
    }
    .pannelList {
      border-top: 1px solid color.$gray-gainsboro;
      position: relative;
      padding: 20px 25px 15px;
      @include breakpoint('medium') {
      }
      p {
        font-size: 14px;
      }
    }
    .btnLink {
      color: color.$primary-color;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .ctaSectionModal {
    > button {
      @media screen and (min-width: 768px) {
        position: absolute;
        top: 20px;
        right: 20px;
        min-width: 197px;
      }
      &:disabled {
        opacity: 0.3;
        pointer-events: none;
      }
    }
  }

  .listingSection {
    max-height: 50vh;
    overflow-y: scroll;
    @include breakpoint('medium') {
      max-height: 38vh;
      overflow-y: auto;
      margin-left: -25px;
      margin-right: -25px;
    }
  }
  .change-store-list {
    opacity: 0.3;
  }
}

.make-your-store-button {
  padding: 5px;
}

.mainModalClass {
  .store-modal-dialog {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    max-width: 100%;
    @include breakpoint('medium') {
      margin: 0 auto;
      max-width: 550px;
      min-height: 100%;
    }
  }
}

.store-search-input-wrapper {
  display: flex;
  gap: 15px;
}

.stock-unavailable {
  color: color.$red2;
  font-weight: typography.$bold;
}
.stock-available {
  color: color.$green2;
  font-weight: typography.$bold;
}
.stock-inEligible {
  color: color.$gray;
  font-weight: typography.$bold;
}

.stock-eligibility {
  position: absolute;
  top: 25px;
  left: 42%;
}
.store-modal-wrapper {
  .store-pdp-modal-dailog {
    max-width: 88%;
    margin: 50px auto 20px;
    @include breakpoint('small') {
      max-width: 92%;
    }
    @include breakpoint('medium') {
      max-width: 700px;
      align-items: center;
      margin: 30px auto 0;
    }
    .modalContainer {
      border-radius: 5px 5px 4px 4px;
      border: none;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
      &:after {
        display: none;
      }
      div[class*='modal-header'] {
        button {
          right: -10px;
          top: -10px;
          width: 26px;
          height: 26px;
          background: url(/assets/images/LakeshoreSprites_v16.svg) 0 -78px /
            260px 260px;
          cursor: pointer;
          @include breakpoint('medium') {
            top: -10px;
            right: -10px;
            width: 35px;
            height: 35px;
            background: url(/assets/images/LakeshoreSprites_v16.svg) 0 -105px /
              350px 350px;
          }
        }
      }
      .store-search-input-wrapper {
        input {
          margin-bottom: 0;
        }
      }
    }
    .pannelList {
      padding: 18px 20px 12px 20px;
      > div {
        p:last-child {
          margin-bottom: 5px;
        }
      }
    }
    .listingSection {
      @include breakpoint('medium') {
        margin-left: -25px;
        padding-right: 0;
      }
    }
    .stock-eligibility {
      left: 39%;
    }
    .ctaSectionModal {
      button {
        right: 20px;
        padding: 5px 9px;
      }
    }
    .inputContainer {
      padding-top: 12px;
    }
    .inputClass {
      input {
        margin-bottom: 0;
        font-size: 16px;
        touch-action: manipulation;
        @include breakpoint('small') {
          font-size: 14px;
        }
      }
    }
  }
}

.subHeading {
  padding-left: 25px;
  padding-top: 20px;
  @include breakpoint('medium') {
    padding: 0;
  }
}

.mainModalClass {
  .selected-store-modal {
    @include breakpoint('medium') {
      margin: 0 20px;
    }
  }
}

.modalContainer {
  &.selected-store-modal {
    div[class*='modal-header'] {
      button {
        right: -12px;
        top: -12px;
        width: 26px;
        height: 26px;
        background: variables.$background-lakeshore-sprites 0 -78px/260px 260px;
        @include breakpoint('medium') {
          width: 35px;
          height: 35px;
          background: url(/assets/images/LakeshoreSprites_v16.svg) 0 -105px /
            350px 350px;
        }
      }
    }
    &::after {
      content: none;
    }
  }
}

.ropis-store-close {
  color: color.$red2;
  font-size: 13px;
}
