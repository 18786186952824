
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.product-page-small-title {
  line-height: 23px;
  font-weight: typography.$bold;
  color: color.$black-color;
  display: block;
  a {
    color: color.$primary-color;
  }
}

.product-page-description {
  font-weight: typography.$light;
  color: color.$black-color;
  margin: 0 0 15px;
  a {
    color: color.$primary-color;
  }
}
