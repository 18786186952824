
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.stars {
  width: 100px;
  height: 18px;
  background: variables.$background-lakeshore-sprites -400px -442px/1000px
    1000px;
  position: relative;
  float: left;
  margin-right: 0px;

  span {
    height: 18px;
    background: variables.$background-lakeshore-sprites -300px -442px/1000px
      1000px;
    position: absolute;
  }
}

.gold {
  color: color.$yellow2;
}

.out-of {
  margin-left: 4px;
}

.review-rating-wrap {
  padding: 40px 0 20px;
  border-top: 1px solid color.$light-gray;
  border-bottom: 1px solid color.$light-gray;
  margin-top: 28px;
}

.review-title {
  margin: 0 0 5px;
  font-size: 22px;
  line-height: 29px;
  font-weight: typography.$bold;
  color: color.$black-color;
}

.review-list-subtitle {
  font-size: 17px;
  font-weight: typography.$bold;
  letter-spacing: -0.015em;
  margin: 0 0 20px;
  color: color.$black-color;
}

.review-button {
  display: block;
  font-weight: typography.$medium;
  margin: 0 0 20px;
  width: 100%;
  padding: 9px 32px;
  font-size: 16px;
  @include breakpoint('medium') {
    width: 156px;
    padding: 5px 15px;
    font-size: 14px;
  }
}

.overall-rating {
  display: block;
  color: color.$black-color;
  font-weight: typography.$bold;
  margin-bottom: 10px;
}

.overall-review-count {
  font-size: 14px;
  line-height: 22px;
  color: color.$black-color;
}

.sort-by-label {
  display: block;
  font-weight: typography.$bold;
  color: color.$black-color;
  margin-top: 20px;
  @include breakpoint('medium') {
    margin-top: 0;
  }
}

.sort-by-select {
  width: 100%;
  display: block;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: color.$grey10;
  box-shadow: none;
  border-radius: 4px;
  border: 1px solid color.$light-gray;
  background-color: transparent;
  -webkit-appearance: none;
  font-weight: typography.$light;
  option {
    color: color.$black-color;
    font-weight: typography.$light;
  }
  &:focus {
    border-color: color.$light-blue-shadow;
    outline: 0;
    -webkit-box-shadow:
      inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow:
      inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
}

.select-wrapper {
  width: 100%;
  @include breakpoint('medium') {
    width: 312px;
  }
  @include breakpoint('large') {
    width: 242px;
  }
  @include breakpoint('xlarge') {
    width: 292px;
  }
  .dropdown-wrapper {
    position: relative;
    .select-caret {
      top: 15px;
    }
  }
}

.ratings-sections {
  margin: 25px 0 0;
}

.review-title-text {
  color: color.$black-color;
  font-weight: typography.$bold;
  vertical-align: top;
  margin-left: 12px;
}

.pdp-reviewdate {
  font-size: 17px;
  color: color.$black-color;
  margin-bottom: 10px;
  line-height: 27px;
  @include breakpoint('small') {
    font-size: 14px;
    line-height: inherit;
  }
  @include breakpoint('medium') {
    font-size: 13px;
  }
  .font-italic {
    font-style: italic;
  }
  .review-user {
    margin-right: 5px;
  }
}

.pdp-review-description {
  margin-bottom: 30px;
  font-weight: typography.$light;
  font-size: 17px;
  line-height: 27px;
  color: color.$black-color;
  @include breakpoint('small') {
    font-size: 14px;
    line-height: inherit;
  }
}

.recommended-text {
  font-size: 17px;
  color: color.$black-color;
  font-weight: typography.$light;
  line-height: 27px;
  margin-left: 5px;
  display: inline-block;
  margin-left: 10px;
  @include breakpoint('small') {
    font-size: 13px;
    line-height: 22px;
  }
}

.icn-check-hvy {
  color: color.$ternary-btn-color;

  &::before {
    content: '\e911';
  }
}

.helpful-text {
  font-size: 17px;
  line-height: 27px;
  color: color.$black-color;
  vertical-align: top;
  letter-spacing: 0;
  font-weight: typography.$light;
  @include breakpoint('small') {
    font-size: 13px;
    line-height: inherit;
  }
}

.btn-review {
  background-color: #fff;
  border: none;
  color: color.$primary-color;
  font-size: 17px;
  line-height: 21px;
  margin-left: 15px;
  opacity: 1;
  vertical-align: text-bottom;
  margin-top: -2px;
  font-weight: typography.$light;
  @include breakpoint('medium') {
    font-size: 13px;
    line-height: inherit;
    font-weight: typography.$medium;
  }
  &:hover {
    text-decoration: underline;
  }
}

.btn-review-no {
  margin-left: 0;
  @include breakpoint('large') {
    margin-left: 15px;
  }
}

.feedback-text {
  color: color.$black-color;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: typography.$light;
  &.green {
    color: color.$green3;
  }
}

.btn-success {
  color: color.$gray-silver;
  pointer-events: none;
  cursor: not-allowed;
}

.review-list-pagination-wrapper {
  justify-content: center;
  display: flex;
  margin: 15px 0 30px;
}

.review-helpful {
  flex-direction: column;
  @include breakpoint('medium') {
    flex-direction: row;
  }
}
.review-entry {
  margin-bottom: 40px;
  @include breakpoint('medium') {
    margin-bottom: 25px;
  }
}
.review-title-wrap {
  overflow: hidden;
  margin: 0;
  @include breakpoint('medium') {
    margin-top: 8px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1180px) {
  .review-entry .pdp-review-description-wrap {
    width: 100%;
  }
}
